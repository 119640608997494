import "fslightbox"
import $ from "jquery"
import Cookies from "js-cookie"
import throttle from "lodash/throttle"
import lozad from "lozad"

import inject from "svg-inject"
import Swiper, { Navigation, Pagination, Autoplay, Controller, Lazy } from 'swiper';
import "../styles/style.scss"
import "../styles/new-pages.scss"
import * as forged from "./forged"
import "hint.css"
import * as forms from './forms'
import './about'
import './dynamicAdapt'
import { parseInt } from "lodash"
import './common/twinspark'
import './common/popup-banner'
import './common/header'
import './common/common'
import './common/scroll-top'

window.ga4SendSelectItemEvent = function ga4SendSelectItemEvent(productId) {
  if (__ga_ecommerce_items && productId) {
    let item = __ga_ecommerce_items.find(x => x.item_id == productId)
    if (item) {
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            item
          ]
        }
      })
    }
  }
}

window.addEventListener("pageshow", function(event) {
  const historyTraversal = event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2);
  const needToReload = location.pathname.startsWith("/diski/") || location.pathname.startsWith("/shiny/");
  if (historyTraversal && needToReload) {
    window.location.reload();
  }
});

window.addEventListener("load", () => {
  const shareButtonList = document.querySelectorAll(".share-button")
  if (shareButtonList) {
    shareButtonList.forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault()
        document.documentElement.classList.add("_hidden")
        document.getElementById("modal-share").classList.add("_visible")
      })
    })
  }

  if (document.getElementById("product-works-slider")) {
    new Swiper("#product-works-slider", {
      modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
      spaceBetween: 15,
      slidesPerView: 1,
      navigation: {
        nextEl: ".page_product .slider-wrap .swiper-button-next",
        prevEl: ".page_product .slider-wrap .swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
    })
  }

  window.initFeedbackSliders = function() {
    document.querySelectorAll(".review-slider:not(.swiper-initialized)").forEach(el => {
      new Swiper(el, {
        modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
        slidesPerView: 1,
        loop: true,
        navigation: {
          nextEl: el.querySelector(` .swiper-button-next`),
          prevEl: el.querySelector(` .swiper-button-prev`),
        },
      })
    })
  }
  initFeedbackSliders()


  if (document.getElementById("index-slider")) {
    new Swiper("#index-slider", {
      modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
      },
      slidesPerView: 1,
      loop: true,
      pagination: {
        el: "#index-slider .swiper-pagination",
        clickable: true,
      },
    })
  }

  if (document.querySelectorAll(".product-slider").length) {
    new Swiper(".product-slider", {
      modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
      spaceBetween: 15,
      slidesPerView: 1,
      pagination: {
        el: '.swiper__dots',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        476: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        992: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },
    })
  }

  if (document.querySelectorAll(".portfolio-slider").length) {
    new Swiper(".portfolio-slider", {
      modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
      spaceBetween: 15,
      lazy: {
        loadPrevNext: true,
      },
      loop: true,
      watchOverflow: true,
      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
      },
      pagination: {
        el: '.swiper__dots',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3,
        },
      },
    })

  }

  const cityList = document.querySelectorAll(".page_contacts .list .list-item")
  cityList.forEach((el, index) => {
    el.onclick = e => {
      e.preventDefault()

      const item = cityList[index]

      if (!item.classList.contains("_active")) {
        cityList.forEach(i => i.classList.remove("_active"))
      }

      item.classList.add("_active")
    }
  })

  const hideSiteLayout = () => {
    document.getElementById("site-layout").classList.add("d-none")
  }

  const showSiteLayout = () => {
    document.getElementById("site-layout").classList.remove("d-none")
  }

  document.getElementById("site-layout").onclick = e => {
    e.preventDefault()

    hideSiteLayout()
    document.documentElement.classList.remove("_hidden")

    const basket = document.getElementById("component_basket")
    if (basket) {
      basket.classList.remove("_visible")
    }

    const catalog = document.getElementById("component_catalog-filter")
    if (catalog) {
      catalog.classList.remove("_visible")
    }
  }

  const openSideFilter = function() {
    showSiteLayout();
    document.getElementById("component_catalog-filter").classList.add("_visible")
    document.documentElement.classList.add("_hidden")
  }

  const closeSideFilter = function() {
    hideSiteLayout();
    document.getElementById("component_catalog-filter").classList.remove("_visible")
    document.documentElement.classList.remove("_hidden")
  }

  let closeIcon = document.querySelector("#component_catalog-filter .title .close-icon")
  if (closeIcon) {
    closeIcon.onclick = e => {
      e.preventDefault()
      closeSideFilter()
    }
  }

  const tabHeaderList = document.querySelectorAll(".tabs-header .tab")
  const tabContentList = document.querySelectorAll(".tabs-content")

  if (tabHeaderList) {
    tabHeaderList.forEach((el, index) => {
      el.onclick = e => {
        e.preventDefault()

        tabHeaderList.forEach(el => el.classList.remove("_active"))

        el.classList.add("_active")

        tabContentList.forEach(el => el.classList.remove("_visible"))

        tabContentList[index].classList.add("_visible")
      }
    })
  }

  const catalogSizeList = document.querySelectorAll(
    ".page_catalog .filter-wrap .size-list .size"
  )
  if (catalogSizeList.length) {
    catalogSizeList.forEach(el => {
      el.onclick = e => {
        e.preventDefault()

        catalogSizeList.forEach(el => el.classList.remove("_active"))

        e.target.classList.add("_active")
      }
    })
  }

  window.setCatalogViewType = e => {
    if (e) e.preventDefault()
    let catalogViewType = document.querySelectorAll(
      ".page_catalog .filter-wrap .view-type-item"
    )
    catalogViewType.forEach(el => el.classList.remove("_active"))

    e.currentTarget.classList.add("_active")

    const type = e.currentTarget.getAttribute("data-type")

    Cookies.set("catalogViewType", type, { expires: 30 })

    const prodList = document.querySelectorAll(".component_product-item")
    const prodWrap = document.getElementById("catalog-prod-wrap")

    if (type === "list") {
      prodWrap.classList.add("_full")
      prodList.forEach(el => el.classList.add("_full"))
    } else if (type === "grid") {
      prodWrap.classList.remove("_full")
      prodList.forEach(el => el.classList.remove("_full"))
    }
  }

  const catalogViewType = document.querySelectorAll(
    ".page_catalog .filter-wrap .view-type-item"
  )
  if (catalogViewType.length) {
    catalogViewType.forEach((el) => {
      el.onclick = window.setCatalogViewType
    })
  }

  /**
   * Воспроизведение видео
   */
  const aboutPlayBtn = document.querySelector(".page_about .play-btn")
  if (aboutPlayBtn) {
    aboutPlayBtn.onclick = e => {
      e.preventDefault()

      const parent = aboutPlayBtn.parentNode

      aboutPlayBtn.classList.add("d-none")
      let iframe = parent.querySelector("iframe")
      iframe.classList.remove("d-none")
      iframe.style.height = e.currentTarget.parentNode.offsetHeight + "px"
      parent.querySelector("img").classList.add("d-none")
    }
  }

  const paymentItem = document.querySelectorAll(
    ".page_basket .contact-item .payment-item"
  )
  if (paymentItem.length) {
    paymentItem.forEach(el => {
      el.onclick = e => {
        e.preventDefault()

        paymentItem.forEach(i => i.classList.remove("_active"))

        el.classList.add("_active")
        let val = el.getAttribute("value")
        document.getElementById("payment_type").value = val
        let btn = el.closest("form").querySelector(".btn-order button")
        if (val === "on_delivery" || val == "installment") {
          btn.innerText = "Оформить заказ"
        } else {
          btn.innerText = "Оплатить заказ"
        }
      }
    })
  }

  const productMainImgWrap = document.getElementById("product-main-img-wrap")
  const productMainImg = document.getElementById("product-main-img")
  const productThumbImgList = document.querySelectorAll(
    ".page_product .product-data .image-wrap .thumb-imgs .nav-thumb"
  )
  if (productThumbImgList.length) {
    productThumbImgList.forEach((el, index) => {
      el.onclick = e => {
        e.preventDefault()

        productThumbImgList.forEach(i => i.classList.remove("_active"))

        el.classList.add("_active")

        const imgSrc = el.getAttribute("data-full-image")

        productMainImg?.setAttribute("src", imgSrc)

        productMainImgWrap.querySelectorAll("a").forEach((l, lIndex) => {
          if (lIndex === index) {
            l.classList.remove("d-none")
            l.classList.add("d-block")
          } else {
            l.classList.add("d-none")
            l.classList.remove("d-block")
          }
        })
      }
    })
  }


  async function initImageZoom() {
    let ImageZoom = await import(/* webpackChunkName: "js-image-zoom" */"js-image-zoom")
    const imageZoomOptions = {
      width: productMainImgWrap.clientWidth,
      height: productMainImgWrap.clientHeight,
      zoomPosition: "original",
    }

    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      new ImageZoom(productMainImgWrap, imageZoomOptions)
    }
  }

  if (productMainImgWrap) {
    if (productMainImgWrap.classList.contains("_loop")) {
      initImageZoom()
    }
  }

  const changeArticleIframeSize = el => {
    const width = el.clientWidth
    const height = width * 0.5625

    el.style.height = `${height}px`
  }

  const changeNewsImageSize = el => {
    const width = el.clientWidth
    const height = width * 0.75

    el.style.height = `${height}px`
  }

  const changeReviewSliderImageSize = el => {
    if (window.innerWidth < 992) {
      const width = el.clientWidth
      const height = width * 0.75

      el.style.height = `${height}px`
    }
  }

  const reviewSliderList = document.querySelectorAll(".review-slider")
  if (reviewSliderList.length) {
    reviewSliderList.forEach(changeReviewSliderImageSize)
  }

  const articleIframeList = document.querySelectorAll(
    ".page_news-in article iframe"
  )
  if (articleIframeList) {
    articleIframeList.forEach(changeArticleIframeSize)
  }

  const newsList = document.querySelectorAll(".component_article img")
  if (newsList.length) {
    newsList.forEach(changeNewsImageSize)
  }

  async function initImask() {
    let IMask = await import(/* webpackChunkName: "imask" */"imask")
    const phoneMaskInputList = document.querySelectorAll(".phone-mask")
    phoneMaskInputList.forEach(el => {
      new IMask.InputMask(el, {
        mask: "+0 (000) 000-00-00",
      })
      el.addEventListener("focus", function(e) {
        var el = this
        setTimeout(function() {
          el.selectionStart = el.selectionEnd = 10000
        }, 0)
      })
    })
  }

  window.initImask = initImask

  if (document.querySelectorAll(".phone-mask")) {
    initImask()
  }

  const basketFileWrap = document.getElementById("basket-file-wrap")
  if (basketFileWrap) {
    basketFileWrap.onchange = e => {
      const file = e.target.files[0]

      const formData = new FormData()
      formData.append("file", file)

      const spanList = document
        .getElementById("text-wrap")
        .querySelectorAll("span")

      spanList[0].classList.add("d-none")
      spanList[1].classList.remove("d-none")

      document.querySelector(".file-wrap").classList.remove("d-none")
      document.querySelector(".file-name").innerText = file.name
    }

    document.querySelector(".file-upload .file .remove").onclick = e => {
      e.preventDefault()

      const spanList = document
        .getElementById("text-wrap")
        .querySelectorAll("span")

      spanList[0].classList.remove("d-none")
      spanList[1].classList.add("d-none")

      document.querySelector(".file-wrap").classList.add("d-none")
    }
  }

  window.twinspark.func({
    openSideFilter: function(o) {
      openSideFilter()
    },
    applyFastSeason: function(o) {
      const form = document.querySelector("#component_catalog-filter form")
      form.querySelectorAll("[name=seasons]").forEach(el => el.checked = false)
      if (o.el.value == "letnie") {
        form.querySelector("[name=seasons][value=letnie]").checked = true
      } else if (o.el.value) {
        form.querySelector("[name=seasons][value=zimnie]").checked = true
        form.querySelectorAll("[name=spikes]").forEach(el => el.checked = false)
        form.querySelector(`[name=spikes][value=${o.el.value}]`).checked = true
      }
      twinspark.trigger(form, "submit")
    },
    applyFastFilter: function(o) {
      var el = document.querySelector('#component_catalog-filter [name=' + o.el.name + ']')
      el.value = o.el.value
      twinspark.trigger(el.closest('form'), 'submit');
    },
    setCatalogViewType: function(o) {
      window.setCatalogViewType(o.event)
    },
    closeSideFilter: function(o) {
      closeSideFilter()
    },
  });
})
