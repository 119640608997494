
import Swiper, { Navigation, Pagination, Autoplay, Lazy, Controller } from 'swiper';

import $ from "jquery"

window.addEventListener("load", function () {
	var brands = new Swiper(".n-banner__init", {
		//instance need to be unique (ex: some-slider)
		modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
		watchSlidesVisibility: true,
		lazy: true,
		loop:
			document.querySelectorAll(".n-banner__init .swiper-slide").length > 1
				? true
				: false,
		watchOverflow: true,
		autoHeight: true,
		autoplay: {
			delay: 8000,
			disableOnInteraction: false,
		},
		pagination: {
			el: ".n-banner__dots",
			type: "bullets",
			clickable: true,
		},
	})

	var nworks = new Swiper(".n-works__init", {
		//instance need to be unique (ex: some-slider)
		modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
		watchSlidesVisibility: true,
		lazy: true,
		loop:
			document.querySelectorAll(".n-works__init .swiper-slide").length > 1
				? true
				: false,
		watchOverflow: true,
		autoHeight: true,
		autoplay: {
			delay: 8000,
			disableOnInteraction: false,
		},
		navigation: {
			prevEl: ".n-works .prev",
			nextEl: ".n-works .next",
		},
		pagination: {
			el: '.swiper__dots',
			type: 'bullets',
			clickable: true
		},
	});

	if (document.querySelector(".bottom-slider__init")) {
		new Swiper(".bottom-slider__init", {
			modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
			spaceBetween: 20,
			
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			watchOverflow: true,
			lazy: true,
			watchOverflow: true,
			centeredSlides: true,
			roundLengths: true,
			pagination: {
				el: '.bottom-slider__dots',
				type: 'bullets',
				clickable: true,
				watchOverflow: true
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					loop:
				document.querySelectorAll(
					".bottom-slider__init .swiper-slide"
				).length > 1
					? true
					: false,
				},
				575: {
					slidesPerView: 2,
					loop:
				document.querySelectorAll(
					".bottom-slider__init .swiper-slide"
				).length > 2
					? true
					: false,
					centeredSlides:
				document.querySelectorAll(
					".bottom-slider__init .swiper-slide"
				).length < 2
					? true
					: false,
				},
				992: {
					slidesPerView: 3,
					loop:
				document.querySelectorAll(
					".bottom-slider__init .swiper-slide"
				).length > 3
					? true
					: false,
					centeredSlides:
				document.querySelectorAll(
					".bottom-slider__init .swiper-slide"
				).length < 3
					? true
					: false,
				},
			},
		})
	}

	document.querySelectorAll(".n-faq__item").forEach(el => {
		el.addEventListener("click", function (e) {
			e.preventDefault()
			$(this).toggleClass("active")
			$(this).find(".n-faq__text").slideToggle()
			$(".n-faq__item").not(this).removeClass("active")
			$(".n-faq__item").not(this).find(".n-faq__text").slideUp()
		})
	})

	const myCustomSlider = document.querySelectorAll(
		".single-product-slider__main-init"
	)
	const myCustomGalleryThumbs = document.querySelectorAll(
		".single-product-slider__nav-init"
	)

	for (var i = 0; i < myCustomSlider.length; i++) {
		myCustomSlider[i].classList.add("single-product-slider__main-init-" + i)
		myCustomGalleryThumbs[i].classList.add(
			"single-product-slider__nav-init-" + i
		)

		var galleryThumbs = new Swiper(".single-product-slider__nav-init-" + i, {
			modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
			spaceBetween: 4,
			slidesPerView: "auto",
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			direction: "vertical",
			lazy: true,
			
		})

		var galleryTop = new Swiper(".single-product-slider__main-init-" + i, {
			modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
			watchSlidesVisibility: true,
			lazy: true,
			loop:
				document.querySelectorAll(
					".single-product-slider__nav-init-" + i + " .swiper-slide"
				).length > 1
					? true
					: false,
			thumbs: {
				// el: '.thumbs-class',
				// slidesPerView: 5,
				swiper: galleryThumbs,
			},
		})
	}

	if (document.querySelector(".single-product__additional-init")) {
		new Swiper(".single-product__additional-init", {
			modules: [Navigation, Pagination, Autoplay, Controller, Lazy],
			spaceBetween: 4,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			watchOverflow: true,
			lazy: true,
			pagination: {
				el: '.single-product__additional-dots',
				type: 'bullets',
				clickable: true,
				watchOverflow: true
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 4,
				},
			},
		})
	}

	if (document.querySelector(".tabs-slider__init")) {
    var activeIndex = 0;

    if (sessionStorage.hasOwnProperty('activeIndex')){
        activeIndex = sessionStorage.getItem('activeIndex')
    }
		let tabsslider = new Swiper(".tabs-slider__init", {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			initialSlide: activeIndex,
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			watchOverflow: true,
      noSwipingSelector: "input[type=text], .form-label",
			// noSwipingClass: 'form-label',
			lazy: true,
			navigation: {
				prevEl: ".tabs-slider__nav-prev",
				nextEl: ".tabs-slider__nav-next",
			},
		});
		tabsslider.on( 'slideChange', function() {
			$('.tabs-slider select').blur();
			sessionStorage.setItem('activeIndex', tabsslider.activeIndex)
	    });
	}

});
