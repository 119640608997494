import $ from "jquery"
import throttle from "lodash/throttle"
import lozad from "lozad"
// import inject from "svg-inject"

window.addEventListener("load", () => {
  // const svgList = document.querySelectorAll(".svg-img")
  // svgList.forEach(el => inject(el))

  const observer = lozad()
  observer.observe()

  $(document.body).on("click", ".modal-close, .modal .shadow", e => {
    e.preventDefault()
    closeModal()
  })

  $(document.body).on("click", ".js-open-modal", e => {
    e.preventDefault()
    const id = e.currentTarget.getAttribute("data-modal-id")
    closeModal()

    if (document.getElementById(id)) {
      document.documentElement.classList.add("_hidden")
      document.getElementById(id).classList.add("_visible")
    }
    if (id == 'modal-call-order') {
      initImask()
    }
  })

  const accordeonList = document.querySelectorAll(
    ".component_accordeon._clickable"
  )
  accordeonList.forEach((el, index) => {
    el.onclick = e => {
      e.preventDefault()

      const item = accordeonList[index]

      if (!item.classList.contains("_active")) {
        accordeonList.forEach(i => i.classList.remove("_active"))
      }

      item.classList.toggle("_active")
    }
  })

  $.ajaxSetup({
    beforeSend: function(xhr, settings) {
      function getCookie(name) {
        var cookieValue = null
        if (document.cookie && document.cookie != "") {
          var cookies = document.cookie.split(";")
          for (var i = 0; i < cookies.length; i++) {
            var cookie = $.trim(cookies[i])
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == name + "=") {
              cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
              break
            }
          }
        }
        return cookieValue
      }
      if (!(/^http:.*/.test(settings.url) || /^https:.*/.test(settings.url))) {
        // Only send the token to relative URLs i.e. locally.
        xhr.setRequestHeader("X-CSRFToken", getCookie("csrftoken"))
      }
    },
  })

  function setMeta(name, val) {
    let el = document.querySelector(`meta[name="${name}"]`)
    if (el) {
      el.setAttribute("content", val)
    }
  }

  window.updateSeo = function(title, desc, keywords, url) {
    document.title = title
    setMeta('description', desc)
    setMeta('keywords', keywords)
    history.pushState({}, '', url)
  }

  const smoothScrollBtns = document.querySelectorAll(".scroll-to-top")
  if (smoothScrollBtns.length) {
    smoothScrollBtns.forEach(el => {
      el.onclick = e => {
        e.preventDefault()

        window.scrollTo({ top: 0, behavior: "smooth" })
      }
    })
  }

  const calcOffsetTop = () => {
    // if (window.location.pathname === "/calculator") {
    // 	return
    // }

    const header = document.getElementById("component_header")
    const body = document.body
    const bottom = header.querySelector(".bottom")
    const bottomHeight = bottom.offsetHeight

    let paddingTop = 0
    if (header.querySelector(".top").offsetHeight < window.scrollY) {
      bottom.classList.add("_sticky")

      paddingTop = `${bottomHeight}px`
    } else {
      bottom.classList.remove("_sticky")
    }

    body.style.paddingTop = paddingTop
  }
  calcOffsetTop()

  const calcMobileMenuTopOffset = () => {
    const header = document.getElementById("component_header")
    const mobileMenu = document.getElementById("mobile-menu")
    const bottom = header.querySelector(".bottom")

    if (bottom.classList.contains("_sticky")) {
      mobileMenu.style.top = `${bottom.clientHeight}px`
      mobileMenu.style.height = `calc(100% - ${bottom.clientHeight}px)`
    } else {
      mobileMenu.style.top = `${header.clientHeight}px`
      mobileMenu.style.height = `calc(100% - ${header.clientHeight}px)`
    }
  }
  calcMobileMenuTopOffset()

  window.onresize = throttle(() => {
    calcOffsetTop()
    calcMobileMenuTopOffset()
  }, 200)

  window.onscroll = throttle(() => {
    calcOffsetTop()
    calcMobileMenuTopOffset()
  }, 100)

  $(document.body).on('click', '.vs_widget_button_svg', function() {
    document.querySelector('.vs_widget_submenu').classList.toggle('active');
    document.querySelector('.vs_widget_submenu').classList.toggle('vs_widget_hidden');
    document.querySelector('.vs_widget_button_svg').classList.toggle('active_vs_widget_button');
  });

  $(document.body).on('click', '.badge-wrap', e => e.preventDefault() && e.stopPropagation())

  // $(document.body).on('click', '#popup-banner-overlay, #popup-banner-close a', function() {
  //   document.getElementById('popup-banner-container').classList.remove('_visible')
  // });
})
