import Swiper, { Navigation, Pagination, Lazy, EffectFade } from 'swiper';

import $ from "jquery"


document.addEventListener('DOMContentLoaded', function() {
	var certificates = new Swiper(".certificates__init", {
		modules: [Navigation, Pagination, Lazy],
		lazy: true,
		spaceBetween: 20,
		navigation: {
			prevEl: '.certificates__prev',
			nextEl: '.certificates__next',
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
				slidesPerGroup: 2,
				loop: $('.certificates__init .swiper-slide').length > 2 ? true : false,
			},
			575: {
				slidesPerView: 3,
				slidesPerGroup: 3,
				loop: $('.certificates__init .swiper-slide').length > 3 ? true : false,
			},
			992: {
				slidesPerView: 5,
				slidesPerGroup: 5,
				loop: $('.certificates__init .swiper-slide').length > 5 ? true : false,
			},
			1170: {
				slidesPerView: 6,
				slidesPerGroup: 6,
				loop: $('.certificates__init .swiper-slide').length > 6 ? true : false,
			},
		},
	});

	var gallery = new Swiper(".a-gallery__slider-init", {
		modules: [Navigation, Pagination, Lazy],
		lazy: true,
		spaceBetween: 12,
		navigation: {
			prevEl: '.a-gallery__prev',
			nextEl: '.a-gallery__next',
		},
		pagination: {
			el: '.a-gallery__dots',
			type: 'bullets',
			clickable: true
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				loop: $('.a-gallery__slider-init .swiper-slide').length > 1 ? true : false,
			},
			575: {
				slidesPerView: 2,
				slidesPerGroup: 2,
				loop: $('.a-gallery__slider-init .swiper-slide').length > 2 ? true : false,
			},
			992: {
				slidesPerView: 3,
				slidesPerGroup: 3,
				loop: $('.a-gallery__slider-init .swiper-slide').length > 3 ? true : false,
			},
		},
	});

	// var about = new Swiper(".a-about__slider-init", {
	// 	modules: [Navigation, Pagination, Lazy, EffectFade],
	// 	loop: $('.a-about__slider-init .swiper-slide').length > 1 ? true : false,
	// 	lazy: true,
	// 	slidesPerView: 1,
	// 	navigation: {
	// 		prevEl: '.a-about__slider-nav--prev',
	// 		nextEl: '.a-about__slider-nav--next',
	// 	},
	// 	effect: 'fade',
	// 	fadeEffect: {
	// 		crossFade: true
	// 	},
	// 	pagination: {
	// 		el: '.a-about__slider-nav--dots',
	// 		type: 'bullets',
	// 		clickable: true
	// 	},
	// });

	$('.faq__item').click(function (e) { 
		e.preventDefault();
		$(this).toggleClass('opened');
		$(this).find('.faq__item-desc').slideToggle();
		$('.faq__item').not(this).removeClass('opened');
		$('.faq__item').not(this).find('.faq__item-desc').slideUp();
	});
});
